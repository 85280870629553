import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { WordStatus } from '../../types';
import { IRootState } from '../../store/reducers/state';

export const Stats: FC = () => {
  const { meta } = useSelector(((state: IRootState) => state.word));

  const knowStatus = meta ? Object.values(meta)?.filter(
      ({ status }) => status === WordStatus.AlreadyKnow
  )?.length : 0;
  const learnStatus = meta ? Object.values(meta)?.filter(
      ({ status }) => status === WordStatus.ToLearn
  )?.length : 0;
  const total = meta ? Object.values(meta).length : 1;
  const knowRate = ((knowStatus * 100) / total).toFixed(2);

  return (
    <div className="stats">
      <div>
        ✅&nbsp;:&nbsp;
        {knowStatus}
      </div>
      <div>
        🧐&nbsp;:&nbsp;
        {learnStatus}
      </div>
      <div>
        🎯&nbsp;:&nbsp;
        {knowRate}
        %
      </div>
    </div>
  );
};
