import React, { FC } from 'react';

interface IInfo {
  isMobile: boolean;
}

const instruction = {
  desktop: [
    'Left Shift - to learn',
    'Right Shift - already know',
    'Space - show translation',
  ],
  mobile: [
    'Touch Left - to learn',
    'Touch Right - already know',
  ],
};

export const Info: FC<IInfo> = ({
  isMobile,
}) => {
  const targetInstructions = isMobile ? instruction.mobile : instruction.desktop;
  return (
    <div className="info">
      <div className="info__inner">
        {!isMobile && <img src="/svg/keyboard.svg" alt="keyboard" />}
        {targetInstructions.map((text) => <div>{text}</div>)}
        <br />
      </div>
      <div style={{ fontSize: 11 }}>
        * Use
        {' '}
        <b>Repetition</b>
        {' '}
        for repeat words
        {' '}
        <br />
        that have been marked for learning
      </div>
    </div>
  );
};
