import React, { FC } from 'react';
import { IWord } from '../../types';

interface ICard {
  word?: IWord;
  isWordsLoaded: boolean;
  isShowTranslate?: boolean;
  side?: string;
  style?: React.CSSProperties;
}

export const WordCard: FC<ICard> = ({
  word,
  isWordsLoaded,
  isShowTranslate,
  side,
  style,
}) => (
  <div className="card">
    <div className="card__inner" style={style}>
      <div className="card__border card__border--right" style={side === 'right' ? style : {}} />
      <div className="card__border card__border--left" style={side === 'left' ? style : {}} />
      <span className="card__id">{word?.id}</span>
      <span className="card__text">
        {word?.text}
      </span>
      <div className={`card__translation ${(isWordsLoaded && !word?.translation) || isShowTranslate ? 'card__translation--show' : ''}`}>
        {word?.translation ?? 'none to repetition'}
      </div>
    </div>
  </div>
);
