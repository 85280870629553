import { handleActions } from 'redux-actions';
import { WordActions } from '../actions';
import { IWords, WordStatus } from '../../types';

const initialState: IWords = {
  words: undefined,
  meta: undefined,
  statistics: undefined,
};

export const WordReducer = handleActions<IWords, any>({
  [WordActions.Type.SET_WORDS]:
        (state, action) => ({
          ...state,
          words: action.payload.words,
        }),
  [WordActions.Type.SET_META]:
        (state, action) => ({
          ...state,
          meta: action.payload.meta,
        }),
  [WordActions.Type.UPDATE_META]:
        (state, action) => {
          const { id, metaWord } = action.payload;

          return {
            ...state,
            meta: {
              ...state.meta,
              [id]: metaWord,
            },
          };
        },

  [WordActions.Type.SET_STATISTICS]:
        (state, action) => ({
          ...state,
          statistics: action.payload.statistics,
        }),
  [WordActions.Type.INCREASE_STATISTICS_BY_STATUS]:
        (state, action) => {
          const statistics = state.statistics || {};
          const { status } = action.payload;

          const date = new Date();
          const day = `${date.getDate()} ${date.toLocaleString('en-US', { month: 'short' })}`;

          let dayStatistics = statistics[day];
          if (!dayStatistics) {
            dayStatistics = [0, 0];
          }

          if (status === WordStatus.AlreadyKnow) {
            dayStatistics[0] += 1;
          } else if (status === WordStatus.ToLearn) {
            dayStatistics[1] += 1;
          }

          return {
            ...state,
            statistics: {
              ...state.statistics,
              [day]: dayStatistics,
            },
          };
        },
}, initialState);
