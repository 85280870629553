import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { WordSection } from '../WordSection';
import { Statistics } from '../Statistics';

export const links = [
  {
    link: '/', title: 'Words', emoji: '🧶', component: () => <WordSection isRepetitionMode={false} />,
  },
  {
    link: '/repetition', title: 'Repetition', emoji: '👀', component: () => <WordSection isRepetitionMode />,
  },
  {
    link: '/stats', title: 'Stats', emoji: '🐶', component: () => <Statistics />,
  },
];

export const Navigation: FC = () => (
  <nav className="nav">
    <ul className="nav__wrapper">
      {links.map(({ link, title, emoji }) => (
        <li>
          <NavLink
            to={link}
            exact
            className="nav__link"
            activeClassName="nav__link--active"
          >
            {title}
            &nbsp;
            {emoji}
          </NavLink>
        </li>
      ))}
    </ul>
  </nav>
);
