import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { IRootState } from '../store/reducers/state';
import { WordEffects } from '../store/effects/word';
import { useMeta } from './meta';
import { WordStatus } from '../types';
import { WordActions } from '../store/actions';

export const useWords = (isRepetitionMode: boolean = false) => {
  const dispatch = useDispatch();
  const { wordsToRepetition, alreadyProcessedWords, updateMeta } = useMeta();

  const currentId = useRef<number>(0);
  const [pointer, setPointer] = useState(0);
  const [prevWordTranslation, setPrevWordTranslation] = useState<string>('none to repetition');

  const {
    words,
    statistics,
  } = useSelector(((state: IRootState) => state.word));

  const targetWords = isRepetitionMode
    ? words?.filter((word) => wordsToRepetition?.[word.id])
    : words?.filter((word) => !alreadyProcessedWords?.[word.id]);

  const currentWord = targetWords?.length
    ? targetWords[pointer]
    : undefined;

  useEffect(() => {
    if (!words) {
      dispatch(WordEffects.fetchWords());
    } else if (!statistics) {
      dispatch(WordEffects.restoreStatistics());
    }
  }, [words, statistics]);

  useEffect(() => {
    if (statistics) {
      dispatch(WordEffects.saveStatistics(statistics));
    }
  }, [statistics]);

  useEffect(() => {
    setTimeout(() => {
      setPrevWordTranslation(currentWord?.translation ?? 'none to repetition');
    }, 300);
  }, [currentWord]);

  const word = currentWord ? {
    ...currentWord,
    translation: prevWordTranslation,
  } : undefined;

  currentId.current = word?.id || 0;

  const nextWord = (status: WordStatus) => {
    if (!currentId.current) {
      return;
    }

    updateMeta(currentId.current, { status });
    dispatch(WordActions.increaseStatisticsByStatus(status));

    if (isRepetitionMode && status === WordStatus.ToLearn) {
      setPointer((prev) => (prev + 1));
    } else {
      setPointer(0);
    }
  };

  return {
    word,
    nextWord,
    isWordsLoaded: !!words?.length,
  };
};
