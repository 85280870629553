import { useCallback, useEffect } from 'react';
import throttle from 'lodash.throttle';

export const useKeyPress = (
  targetKey: string | Array<string>,
  onPress: (isPressed: boolean, key: string) => void,
  shouldBePressed?: boolean,
  throttleMs: number = 500,
) => {
  const onPressThrottled = useCallback(throttle(onPress, throttleMs, { leading: false }), []);

  const wrapper = (code: string, isDown: boolean) => {
    if (code === targetKey || (Array.isArray(targetKey) && targetKey.includes(code))) {
      if (shouldBePressed === undefined || shouldBePressed === isDown) {
        onPressThrottled(isDown, code);
      }
    }
  };

  const downHandler = ({ code }: KeyboardEvent) => wrapper(code, true);
  const upHandler = ({ code }: KeyboardEvent) => wrapper(code, false);

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);
};
