import React, { FC } from 'react';
import { Stats } from '../Stats';
import { useColor } from '../../use/colors';
import { Graph } from '../Graph';

export const Statistics: FC = () => {
  const { hex } = useColor();

  return (
    <section className="word-section">
      <Stats />
      <div className="word-section__inner">
        <div className="card">
          <div className="card__inner card__inner--big" style={{ borderColor: hex() }}>
            <Graph />
          </div>
        </div>
      </div>
    </section>
  );
};
