import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { IRootState } from '../store/reducers/state';
import { WordEffects } from '../store/effects/word';
import { IMetaWord, WordStatus } from '../types';
import { WordActions } from '../store/actions';

export const useMeta = () => {
  const dispatch = useDispatch();

  const { meta } = useSelector(((state: IRootState) => state.word));

  useEffect(() => {
    if (!meta) {
      dispatch(WordEffects.restoreMeta());
    } else {
      dispatch(WordEffects.saveMeta(meta));
    }
  }, [meta]);

  const updateMeta = (id: number, metaWord: IMetaWord) => {
    dispatch(WordActions.updateMeta(id, metaWord));
  };

  const wordsToRepetition = meta ? Object.entries(meta)
    .reduce((acc, [id, wordMeta]) => {
      const { status } = wordMeta;
      if (status === WordStatus.ToLearn) {
        acc[id] = meta;
      }
      return acc;
    }, {} as any) : {};

  return {
    updateMeta,
    wordsToRepetition,
    alreadyProcessedWords: meta,
  };
};
