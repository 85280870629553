export enum WordStatus {
  AlreadyKnow,
  ToLearn,
}

export interface IWord {
  id: number;
  text: string;
  translation: string;
}

export interface IMetaWord {
  status: WordStatus;
}

export type IMeta = Record<number, IMetaWord>;

export type IStatistics = Record<string, [number, number]>;

export interface IWords {
  words?: Array<IWord>;
  meta?: IMeta;
  statistics?: IStatistics;
}
