import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import throttle from 'lodash.throttle';
import { WordCard } from '../WordCard';
import { useKeyPress } from '../../use/keyPress';
import { WordStatus } from '../../types';
import { Stats } from '../Stats';
import { Info } from '../Info';
import { useWords } from '../../use/words';
import { useColor } from '../../use/colors';

interface IWordSection {
  isRepetitionMode: boolean;
}

const isMobile = window.navigator.maxTouchPoints > 1;

export const WordSection: FC<IWordSection> = ({
  isRepetitionMode,
}) => {
  const { isWordsLoaded, word, nextWord } = useWords(isRepetitionMode);

  const [isShowTranslate, setIsShowTranslate] = useState<boolean>(false);
  const [side, setSide] = useState<string>();
  const { hex } = useColor();

  const showTranslate = () => setIsShowTranslate(true);
  const hideTranslate = () => setIsShowTranslate(false);

  const handleWrapper = (status: WordStatus) => {
    setIsShowTranslate((prev) => {
      if (prev) {
        hideTranslate();
        nextWord(status);
        // if (isMobile) {
        //   if (status === WordStatus.ToLearn) {
        //     setSide('left');
        //   } else {
        //     setSide('right');
        //   }
        // }
        return false;
      }
      return true;
    });
  };

  const handleKnow = () => handleWrapper(WordStatus.AlreadyKnow);
  const handleLearn = () => handleWrapper(WordStatus.ToLearn);

  const codeHandlers = {
    ShiftLeft: handleLearn,
    ShiftRight: handleKnow,
    Space: showTranslate,
  };

  useKeyPress(['ShiftLeft', 'ShiftRight', 'Space'], (_, code) => {
    // @ts-ignore
    codeHandlers?.[code]?.();
  }, true);

  useEffect(() => {
    setTimeout(() => {
      setSide(undefined);
    }, 500);
  }, [side]);

  const style = useMemo(() => ({
    borderColor: hex(),
  }), [word?.text]);

  const onPressThrottled = useCallback(throttle((callback) => callback(), 500), []);

  return (
    <section className="word-section">
      <Stats />

      <div className="word-section__inner">
        <WordCard
          word={word}
          isWordsLoaded={isWordsLoaded}
          isShowTranslate={isShowTranslate}
          side={side}
          style={style}
        />
      </div>
      {
        isMobile && (
        <div className="word-section__border">
          <div
            className="word-section__border--left"
            // style={side === 'left' ? style : undefined}
            onClick={() => onPressThrottled(handleLearn)}
          />
          <div
            className="word-section__border--right"
            // style={side === 'right' ? style : undefined}
            onClick={() => onPressThrottled(handleKnow)}
          />
        </div>
        )
      }

      <Info isMobile={isMobile} />
    </section>
  );
};
