import { createAction } from 'redux-actions';
import {
  IMeta, IMetaWord, IStatistics, IWord, WordStatus,
} from '../../types';

enum Type {
  SET_WORDS = 'WORD/SET_WORDS',
  SET_META = 'WORD/SET_META',
  UPDATE_META = 'WORD/UPDATE_META',
  SET_STATISTICS = 'WORD/SET_STATISTICS',
  INCREASE_STATISTICS_BY_STATUS = 'WORD/INCREASE_BY_STATUS',
}

const setWords = createAction(
  Type.SET_WORDS,
  (words: Array<IWord>) => ({ words }),
);

const setMeta = createAction(
  Type.SET_META,
  (meta: IMeta) => ({ meta }),
);

const updateMeta = createAction(
  Type.UPDATE_META,
  (id: number, metaWord: IMetaWord) => ({ id, metaWord }),
);

const setStatistics = createAction(
  Type.SET_STATISTICS,
  (statistics: IStatistics) => ({ statistics }),
);

const increaseStatisticsByStatus = createAction(
  Type.INCREASE_STATISTICS_BY_STATUS,
  (status: WordStatus) => ({ status }),
);

export const WordActions = {
  Type,
  setWords,
  setMeta,
  updateMeta,
  setStatistics,
  increaseStatisticsByStatus,
};
