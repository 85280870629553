import React, { FC, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../components/Header';
import { IRootState } from '../store/reducers/state';
import { EnumTheme } from '../types';
import { ThemeEffects } from '../store/effects';
import { links } from '../components/Navigation';

export const Main: FC = () => {
  const dispatch = useDispatch();
  const appTheme = useSelector((state: IRootState) => state.theme);
  const theme = appTheme === EnumTheme.Dark ? 'container--dark' : 'container--light';
  useEffect(() => {
    dispatch(ThemeEffects.restoreTheme());
  }, [dispatch]);

  return (
    <div className={`container ${theme}`}>
      <Header />
      <main>
        {links.map(({ link, component }) => (
          <Route
            path={link}
            exact
            render={component}
          />
        ))}
      </main>
    </div>
  );
};
