import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { IRootState } from '../store/reducers/state';
import { WordEffects } from '../store/effects/word';

export const useStatistics = () => {
  const dispatch = useDispatch();
  const { statistics, meta } = useSelector(((state: IRootState) => state.word));

  useEffect(() => {
    if (!statistics) {
      dispatch(WordEffects.restoreStatistics());
    }
  }, [statistics]);

  useEffect(() => {
    if (!meta) {
      dispatch(WordEffects.restoreMeta());
    }
  }, [meta]);

  const maxValue = Math.max(...statistics
    ? Object.values(statistics).map(([know, learn]) => know + learn)
    : [0]);

  const barData = statistics ? Object.entries(statistics).map(([date, [know, learn]]) => {
    const total = learn + know;

    const barMultiplier = ((total * 100) / maxValue) / 100;

    const knowPercent = ((know * 100) / total) * barMultiplier;
    const learnPercent = ((learn * 100) / total) * barMultiplier;

    return {
      date,
      know,
      learn,
      knowPercent,
      learnPercent,
    };
  }) : [];

  return {
    barData,
  };
};
