import { WordActions } from '../actions';
import { IMeta, IStatistics } from '../../types';

const fetchWords = () => async (dispatch: Function) => {
  try {
    const words = await fetch('/words.json').then((r) => r.json());
    // @ts-ignore
    const normalizedWords = words.map(([text, translation], index) => ({ id: index + 1, text, translation }));
    dispatch(WordActions.setWords(normalizedWords));
  } catch (error) {
    console.error('restoreWords', error);
  }
};

const saveMeta = (payload: IMeta) => async () => {
  try {
    localStorage.setItem('words.meta', JSON.stringify(payload));
  } catch (error) {
    console.error('saveMeta', error);
  }
};

const restoreMeta = () => async (dispatch: Function) => {
  try {
    const meta = JSON.parse(String(localStorage.getItem('words.meta')));
    dispatch(WordActions.setMeta(meta));
  } catch (error) {
    console.error('restoreMeta', error);
  }
};

const saveStatistics = (payload: IStatistics) => async () => {
  try {
    localStorage.setItem('words.statistics', JSON.stringify(payload));
  } catch (error) {
    console.error('saveStatistics', error);
  }
};

const restoreStatistics = () => async (dispatch: Function) => {
  try {
    const statistics = JSON.parse(String(localStorage.getItem('words.statistics')));
    dispatch(WordActions.setStatistics(statistics));
  } catch (error) {
    console.error('restoreStatistics', error);
  }
};

export const WordEffects = {
  fetchWords,
  saveMeta,
  restoreMeta,
  saveStatistics,
  restoreStatistics,
};
